/* UntitledSans-regular */
@font-face {
  font-family: "UntitledSans";
  font-weight: 400;
  src: local("UntitledSans"), url("./fonts/UntitledSans/UntitledSans-Regular.otf");
  src: local("UntitledSans"), url("./fonts/UntitledSans/untitled-sans-regular.woff2") format("woff2");
}


/* UntitledSans-Light */
@font-face {
  font-family: "UntitledSans";
  font-weight: 300;
  src: local("UntitledSans"), url("./fonts/UntitledSans/UntitledSans-Light.otf");
  src: local("UntitledSans"), url("./fonts/UntitledSans/untitled-sans-light.woff2") format("woff2");

}

/* UntitledSans-Medium */
@font-face {
  font-family: "UntitledSans";
  font-weight: 500;
  src: local("UntitledSans"), url("./fonts/UntitledSans/UntitledSans-Medium.otf");
  src: local("UntitledSans"), url("./fonts/UntitledSans/untitled-sans-medium.woff2") format("woff2");

}

/* UntitledSans-Bold */
@font-face {
  font-family: "UntitledSans";
  font-weight: 700;
  src: local("UntitledSans"), url("./fonts/UntitledSans/UntitledSans-Bold.otf");
  src: local("UntitledSans"), url("./fonts/UntitledSans/untitled-sans-bold.woff2") format("woff2");

}
