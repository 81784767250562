@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.App {
  min-height: 100vh;
  width: 100%;
  font-family: UntitledSans, sans-serif;

  --toastify-toast-min-height: 40px;
}

div,
body,
span,
p,
button,
input,
textarea,
option,
label {
  font-size: 15px;
  line-height: 21px;
  letter-spacing: -0.15px;
}

input,
input[type="text"],
input[type="number"],
input[type="password"],
input[type="email"],
input[type="tel"],
select {
  width: 100%;
  font-size: 15px;
  line-height: 21px;
  letter-spacing: -0.15px;
  border-radius: 4px;
  border-color: #f4f4f4;
  background-color: #f4f4f4;
  color: #060606;
}

input:focus,
input[type="text"]:focus,
input[type="number"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
select:focus {
  outline: none;
  border-color: #f18500;
  box-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width))
    var(--tw-ring-color);
}

input:disabled,
input[type="text"]:disabled,
input[type="number"]:disabled,
input[type="password"]:disabled,
input[type="email"]:disabled,
input[type="tel"]:disabled,
select:disabled {
  cursor: not-allowed;
}

input[type="checkbox"] {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border-color: inherit;
  margin-right: 6px;
}

input[type="checkbox"]:checked {
  background-color: #f18500;
}

input[type="radio"] {
  width: 20px;
  height: 20px;
  border-radius: 9999px;
  border-color: inherit;
  background-color: #ececec;
}

input[type="radio"]:hover {
  background-color: #c0c0c0;
}

input[type="radio"]:checked {
  background-color: #f18500;
  background-size: 200%;
}

input[type="radio"]:focus {
  outline: none;
  border: 0;
  box-shadow: none;
}

h1 {
  font-family: UntitledSans;
  font-size: 31px;
  font-weight: 400;
  line-height: 37px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #060606;
}

h2 {
  font-family: UntitledSans;
  font-size: 25px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #060606;
}

h3 {
  font-family: UntitledSans;
  font-size: 19px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #060606;
}

h4 {
  font-family: UntitledSans;
  font-size: 17px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #060606;
}

p {
  font-family: UntitledSans;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: -0.02em;
}

details {
  font-family: UntitledSans;
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -0.02em;
}

/* RJSF Bootstrap classes */
.required,
.text-danger {
  color: #fe4343;
}

/* Hermes classes */
.qmShadow {
  box-shadow: 0 4px 12px #0003;
}

.key_button {
  text-shadow:
    0 0.5px 1px #777,
    0 2px 6px #f2f2f2;
  background: -webkit-linear-gradient(
    top,
    #f9f9f9 0%,
    #d2d2d2 80%,
    #c0c0c0 100%
  );
  transition:
    box-shadow 0.3s ease,
    transform 0.15s ease;
}

@layer components {
  .label-layer {
    @apply px-4 py-2 rounded-lg relative bg-paletteGray-4
        flex justify-center items-center text-sm;
  }

  .label-chip {
    @apply px-[10px] py-[3.5px] rounded-[14px] relative bg-paletteGray-4
        flex justify-center items-center gap-x-1 text-xs;
  }

  /* TODO: Merge the icons layers */
  .icon-button-layer {
    @apply w-[32px] h-[32px] p-2 rounded-md relative
        flex justify-center items-center cursor-pointer
        text-paletteGray-10 bg-paletteGray-2
        hover:text-paletteBlack-1 active:text-paletteBlack-1
        hover:bg-paletteGray-4 active:bg-paletteGray-5;
  }

  .icon-button-layer-transparent {
    @apply w-[32px] h-[32px] p-2 rounded-md relative bg-transparent
        flex justify-center items-center cursor-pointer
        text-paletteBlack-1
        hover:bg-paletteGray-4 active:bg-paletteGray-5;
  }

  .icon-button-layer-circle {
    @apply w-[20px] h-[20px] p-1 rounded-[10px] relative bg-transparent
        flex justify-center items-center cursor-pointer
        text-paletteBlack-1
        hover:text-paletteGray-6 hover:bg-paletteGray-3
        active:bg-paletteGray-4;
  }

  /* The default basic button layer */
  .button-layer {
    @apply px-4 py-2 rounded-md animate-fade
        flex items-center justify-center gap-x-2 text-base font-normal
        bg-paletteGray-2 text-paletteBlack-2
        hover:bg-paletteGray-4 active:bg-paletteGray-5 cursor-pointer
        disabled:opacity-60 disabled:bg-paletteGray-4 disabled:cursor-not-allowed;
  }

  .button-layer-active {
    @apply bg-paletteGray-5;
  }

  /* Button layer for items in selection (transparent bg) */
  .button-select-layer {
    @apply w-full px-3 py-2 rounded-md animate-fade
        flex items-center justify-start text-base font-normal
        bg-transparent text-paletteBlack-2
        hover:bg-paletteGray-4 active:bg-paletteGray-5 cursor-pointer
        disabled:opacity-60 disabled:bg-paletteGray-4 disabled:cursor-not-allowed;
  }

  .button-select-layer-active {
    @apply bg-paletteGray-5;
  }

  /* The default orange basic button layer */
  .button-orange-layer {
    @apply w-full px-4 py-[13.5px] cursor-pointer rounded-lg animate-fade
        flex items-center justify-center gap-x-2
        text-base font-medium text-white bg-paletteOrange
        hover:bg-paletteOrange-lighter hover:text-white
        active:bg-paletteOrange-dark active:text-white
        disabled:opacity-60 disabled:bg-paletteOrange disabled:cursor-not-allowed;
  }

  /* The default dark-gray basic button layer (inherts from button-orange-layer) */
  .button-dark-gray-layer {
    @apply button-orange-layer  py-[9.5px] bg-paletteGray-12
        hover:bg-paletteGray-13 active:bg-paletteGray-11
        disabled:bg-paletteGray-12 disabled:opacity-60 font-normal;
  }

  /* Text or number inputs layer */
  .input-text {
    @apply w-full p-3 py-2 bg-paletteGray-2 border-[1px] border-paletteGray-2 rounded
        text-paletteBlack-2
        focus:border-paletteOrange focus:ring-0
        disabled:cursor-not-allowed disabled:opacity-60;
  }

  /* Select input layer */
  .select-layer {
    @apply w-full rounded border-0 cursor-pointer
        bg-paletteGray-2 text-paletteBlack-2 focus:ring-0
        hover:bg-paletteGray-4 active:bg-paletteGray-5
        disabled:cursor-not-allowed disabled:opacity-60;
  }

  /* Checkbox/Radio layer */
  .checkbox-layer {
    @apply bg-paletteGray-2 cursor-pointer
        focus:ring-0 focus:ring-offset-0 accent-paletteOrange
        border-0
        hover:bg-paletteGray-4
        checked:bg-paletteOrange
        checked:hover:bg-paletteOrange-dark
        checked:focus:bg-paletteOrange
        checked:focus:hover:bg-paletteOrange-dark;
  }

  /* full height with margin */
  .full-height-with-margin {
    height: calc(100vh - 10px);
    margin-bottom: 10px;
  }
}

button {
  font-family: UntitledSans;
}

button:hover {
  background-color: darkgray;
  color: black;
}

button:disabled {
  background-color: gray;
  color: lightgray;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 0.25vw;
  height: 0.25vh;
}

::-webkit-scrollbar:hover {
  background: rgb(206, 201, 201);
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.432);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body,
html,
textarea,
div {
  scrollbar-width: thin;
  scrollbar-color: rgba(136, 136, 136, 0.432) #ffffff00;
}

.w-250px {
  width: 250px;
}

.Toastify__toast {
  margin: 8px !important;
  border-radius: 5px !important;
  background: white !important;
  padding: 8px !important;
  padding-bottom: 12px !important;
  color: black !important;
}

.Toastify__toast-body {
  margin: 0px !important;
  padding: 0px !important;
}

.Toastify__toast--success {
  border: 2px solid #96d976 !important;
}

.Toastify__toast--info {
  border: 2px solid #008fcc !important;
}

.Toastify__toast--warning {
  border: 2px solid #ffc700 !important;
}

.Toastify__toast--error {
  border: 2px solid #fe4343 !important;
}
